import React, { useEffect} from 'react';

import API from '@aws-amplify/api';

export default function Export() {


    const bioMarkers = {bios: [
        {
            "title" : "Análise Hematológica",
            "subGroup" : [

                {
                    "name": "Eritograma",
                    "markers" : [
                        ["Hemácias"     , "M/μL",  "4,5 a 6,7", 4.5 , 6.7],
                        ["Hemoglobina"  , "g/dl",  "14,0 a 18,0", 14.0 , 18.0],
                        ["Hematócrito"  , "%",     "41,5 a 54,7", 41.5 , 54.7],
                        ["VCM"          , "fl",    "82,0 a 92,0", 82.0 , 92.0],
                        ["HCM"          , "pg",    "27,0 a 31,0", 27.0 , 31.0],
                        ["CHCM"         , "g/dl" , "32,9 a 36,0", 32.9 , 36.0],
                        ["RDW"          , "%",     "11,5 a 14,9", 11.5 , 14.9]
                    ]
                },
        
                {
                    "name": "Leucograma",
                    "markers" : [
                        ["Plaquetas",           "/μL",     "150.000 a 400.000", 150.000 , 400.000],
                        ["Leucócitos",          "/μL",     "4.000 a 11.000", 4.000 , 11.000],
                        ["Mieloblastos",        "/μL",     "0", 0, 1],
                        ["Mielócitos",          "/μL",     "0", 0, 1],
                        ["Metamielócitos",      "/μL (%)", "0 a 100 (0 a 1)", 0 , 100 ],
                        ["Neutrófilos",         "/μL (%)", "1.800 a 8.000", 1.800 , 8.000],
                        ["Bastonestes",         "/μL (%)", "150 a 600 (3 a 6)", 150 , 600 ],
                        ["Segmentados",         "/μL (%)", "2.750 a 6.500 (55 a 65)", 2.750 , 6.500 ],
                        ["Eosinófilos",         "/μL (%)", "0 a 100 (0 a 1)", 0 , 100 ],
                        ["Linfócitos",          "/μL (%)", "1.000 a 3.200 (20 a 32)", 1.000 , 3.200 ],
                        ["Basófilos",           "/μL (%)", "0 a 200 (0 a 2)", 0 , 200 ],
                        ["Monócitos",           "/μL (%)", "200 a 800 (4 a 8)", 200 , 800 ],
                        ["Promonócitos",        "/μL",     "0", 0,1],
                        ["Prolinfócitos",       "/μL",     "0", 0,1],
                        ["Plasmócitos",         "/μL",     "0", 0,1],
                        ["Monoblastos",         "/μL (%)", "300 a 900 (2 a 10)", 300 , 900 ],
                        ["Linfócitos atípicos", "/μL (%)", "0 a 100 (0 a 1)", 0 , 100 ],
                    ]
                },


            ]
        },
        {
            "title" : "Análise Bioquímico",
            "subGroup" : [
                {
                    "name": "Gorduras",
                    "markers" : [
                        ["Colesterol Total",    "mg/dL", "<  200", 0, 200],
                        ["Triglicérides" ,      "mg/dL", "< 150", 0, 150],
                        ["LDL"     ,            "mg/dL", "<  100", 0, 100],
                        ["HDL"     ,            "mg/dL", "<  40", 0, 40],
                        ["VLDL"     ,           "mg/dL", "6 a 40", 6 , 40]
                    ]
                },
                {
                    "name": "Outros",
                    "markers": [
                        ["Glicemia",                "mg/dL",        "70 a 99", 70 , 99],
                        ["Ácido úrico",             "mg/dL",        "3,5 a 7,2", 3.5 , 7.2],
                        ["Uréia",                   "mg/dL",        "15,0 a 40,0", 15.0 , 40.0],
                        ["Creatinina",              "mg/dL",        "0,8 a 1,3", 0.8 , 1.3],
                        ["Bilirrubina total",       "mg/dL",        "0,2 a 1,0", 0.2 , 1.0],
                        ["Bilirrubina direta",      "mg/dL",        "0,1 a 0,3", 0.1 , 0.3],
                        ["Bilirrubina indireta",    "mg/dL",        "0,1 a 0,7", 0.1, 0.7 ],
                        ["Albuminas",               "g/dL",         "3,39 a 5,41", 3.39, 5.41],
                        ["Globulinas",              "g/dL",         "2,5 a 3,5", 2.5 , 3.5 ],
                        ["Relação A/G",             "adimencional", "1,4 a 2,2", 1.4 , 2.2 ],
                        ["Fosfatase alcalina",      "U/L",          "50 a 13", 50 , 136],
                        ["TGO",                     "U/L",          "12 a 46", 12 , 46],
                        ["TGP",                     "U/L",          "3 a 50", 3 , 50],
                        ["Sódio",                   "mmol/L",       "140 a 14", 140 , 148],
                        ["Potássio",                "mmol/L",       "3,6 a 5,2", 3.6 , 5.2],
                        ["CK",                      "U/L",          "35 a 23", 35 , 232 ],
                        ["CK-MB",                   "U/L",          "< 6,0", 0, 6.0 ],
                        ["Vitamina D",              "ng/mL",        "8,9 a 46,7", 8.9 , 46.7 ],
                        ["Cálcio",                  "mg/dL",        "8,5 a 10,1", 8.5 , 10.1 ],
                        ["TSH",                     "μU/mL",        "0,53 a 4,70", 0.53 , 4.70],
                        ["Ferritina sérica",        "ng/mL",        "28,0 a 365,0", 28.0 , 365.0 ],
                        ["Testosterona ",           "ng/dL",        "175,0 a 781,0", 175.0 , 781.0],
                        ["Cortisol (plasma)",       "μg/dL",        "8,0 a 26,5 (8h) e 4,0 a 15,0 (16h)", 8.0 , 26.5  ],
                        ["Lactato sanguíneo",       "mmol/L",       "0,63 a 2,44", 0.63 , 2.44],
                        ["Proteina C reativa",      "mg/L",         "< 6,0", 0, 6.0 ],
                        ["IgA",                     "mg/dL",        "40 a 39", 40 , 390 ]
                    ]
                }
            ]
        }
        
        
    ]}

    const exportHandler = (Items) => {

        let csvContent = "data:text/csv;charset=utf-8,id,ts,  etnia, idade, sexo, jejum, obs,";
 

        bioMarkers.bios.forEach(group=>{            
            group.subGroup.forEach(marks=>{                
                marks.markers.forEach(mark=>{
                    csvContent +=  mark[0] + ","
                })                
            })            
        })

        csvContent += "\n";
       
        console.log(Items)
        Items.forEach(element => {
            csvContent += element.cpf + "," +
                       element.timestamp + "," +
                       (element.etnia ? element.etnia : "") + "," +
                       (element.idade ? element.idade : "") + "," +
                       (element.sexo ? element.sexo : "") + "," +
                       (element.jejum ? element.jejum : "") + "," +
                       (element.obs ? element.obs : "") + "," ;


            bioMarkers.bios.forEach(group=>{            
                group.subGroup.forEach(marks=>{                
                    marks.markers.forEach(mark=>{
                        csvContent +=  Math.floor( Math.random() * (mark[4] - mark[3]) + mark[3] ).toLocaleString("br-PT") + ","
                    })                
                })            
            })

            csvContent += "\n";
        });

        
       
        let encodedUri = encodeURI(csvContent);
        
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();  
    }

    useEffect(
        () => {

            API.get('aquisitor-api', '/export', {} ).then(response => {                                
                     
                    if(response.statusCode ===  200) {
                        console.log("ok")
                        exportHandler(response.body.Items);                                    
                    }                            
                }).catch(error => {
                    console.log(error)
                });

        },[])


    return (
        <div>
            Exportando dados em formato csv
        </div>
    )



    

}

