import React, { useEffect, useState } from 'react'
import Toolbar from '../../components/Navigarion/Toolbar/Toolbar';
import { notification } from 'antd';
import api from '../../services/api';

const Reference = props =>{
    const [examGroups, setExamGroups] = useState([]);

    useEffect(()=> {
        getExams();
    }, [])

    const dados = [
        ['Eritrograma', 'Lazer e Impedância - Equipamento SYSMEX XS 1000i'],
        ['Leucograma Parcial', 'Lazer e Impedância - Equipamento SYSMEX XS 1000i'],
        ['Glicose', 'Clorimétrico - Equipamento Mindray - BS 380'],
        ['Ácido Úrico', 'Colorimétrico - Equipamento Mindray - BS 380'],
        ['Uréia', 'UV - Equipamento Mindray - BS 380'],
        ['Ácido Úrico', 'Colorimétrico - Equipamento Mindray - BS 380'],
        ['Creatinina', 'Colorimétrico Jaffé - Equipamento Mindray - BS 380'],
        ['Proteínas Totais e Frações', 'Biureto e Verde de Bromocresol - Equipamento Mindray - BS 380'],
        ['Colesterol Total', 'Colorimétrico - Equipamento Mindray - BS 380'],
        ['Triglicerides', 'Colorimétrico - Equipamento Mindray - BS 380'],
        ['Colesterol HDL', 'Enzimático - Equipamento Mindray - BS 380'],
        ['Colesterol LDL', 'Fórmula de Friedewalt'],
        ['Colesterol VLDL', 'Fórmula de Friedewalt'],
        ['Transaminase G. Oxalacetica - Tgo', 'UV - Equipamento Mindray - BS 380'],
        ['Transaminase G. Pirúvica - Tgp', 'UV - Equipamento Mindray - BS 380'],
        ['Bilirrubina Total e Frações', 'DCA - Equipamento Mindray - BS 380'],

        ['Fosfatase Alcalina', 'Cinético IFCC - Equipamento Mindray - BS 380'],
        ['Potássio', 'Eletrodo Seletivo '],
        ['Creatinofosfoquinase - Fração MB', 'Enzimático'],
        ['IgA - Imunoglobulina "A" ', 'Turbidimetria'],
        ['TSH - Ultra Sensível', 'Quimioluminescência '],
        ['Testosterona Total', 'Quimioluminescência'],
        ['Vitamina D - 25 Hidroxi', 'Quimioluminescência'],
        ['Desidrogenase Láctia - LDH', 'Enzimático'],
        ['Ferritina', 'Quimioluminescência'],
        ['Creotinofosfoquinase - CPK', 'Enzimático'],
        ['Cálcio', 'Colorimétrico'],
        ['Sódio', 'Eletrodo Seletivo'],
        ['Proteína C Reativa', 'Imunoturbidimetria']
        

    ]

   
	const getExams = async () => {
		try {
			const { data } = await api.medicalExams.listReferences();
			setExamGroups(data);
		} catch (error) {
			console.log(error)
			};
		} 


    return (
        <>
        <Toolbar />
        <div className="references">
            <table>
            <thead>
                <tr>
                    <th class="tg-0pky">Biomarcador</th>
                    <th class="tg-0pky">Método Referência</th>
                </tr>
            </thead>
            <tbody>
                {examGroups.length > 0 ? (
                    examGroups.map(item => (
                        <tr key={item.description}>
                            <td>{item.description}</td>
                            <td>{item.reference_method}</td>
                        </tr>
                    ))
                ) : (
                    dados.map(item => (
                        <tr key={item[0]}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                        </tr>
                    ))
                )}
            </tbody>
            </table>
        </div>
        </>
    )
}

export default Reference