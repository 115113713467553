import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from '../../components/UI/Messagens/Message';
import Logo from '../../components/Navigarion/Logo/Logo';


class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			messagens: [],			
			email: '',
			password: '',
			updatePW: false,
			newPasswordCheck: '',
			newPassword: '',
			oldPassword: ''
		};

		this.user = null
	}

	

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value,
			messagens: []			
		});
	};

	checkForm = () =>{
		let newMes = [];
		let valid = true;
		

	
		if(this.state.updatePW) {			

			if(this.state.oldPassword.length < 3) {
				newMes.push({
						id: new Date(),
						type: "error",
						message: "A senha atual deve ser preenchida"});
				valid = false;
			}
			if(this.state.newPassword.length < 3) {

				newMes.push({
					id: new Date(),
					type: "error",
					message: "A nova senha deve ser preenchida"});
				valid = false;
			}
			if(this.state.newPasswordCheck.length < 3) {

				newMes.push({
					id: new Date(),
					type: "error",
					message: "Repita a nova senha."});
				valid = false;
			}

			if(this.state.newPasswordCheck !==  this.state.newPassword ) {				
				

				newMes.push({
					id: new Date(),
					type: "error",
					message: "A nova senha e a repetição não são iguais."});
				valid = false;
				
			}

		} else { 			
			
			const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    		if (!emailRegex.test(String(this.state.email).toLowerCase())) {

				newMes.push({
					id: new Date(),
					type: "error",
					message: "O campo email está inválido."});
				valid = false;
			}

			if (this.state.password < 4) {

				newMes.push({
					id: new Date(),
					type: "error",
					message: "O campo senha está inválido."});
				valid = false;
			}
		}

		this.setState({
			messagens: [...newMes]
		});

		return valid
	}

	handleSubmit = async event => {
		event.preventDefault();

		if(!this.checkForm()) {
			return;
		}

		this.setState({ isLoading: true });

		

		try {
			
			this.user = await Auth.signIn(this.state.email, this.state.password); 
			if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				
				if(this.state.updatePW) {
					await Auth.completeNewPassword(
						this.user,              // the Cognito User Object
						this.state.newPassword,       // the new password					
					{});					
				} else {
					this.setState({
						updatePW: true, 
						isLoading: false
					})
				}
			} 
		} catch (e) {
			
			const oldMessages = this.state.messagens;
			switch (e.code) {
				case "TooManyFailedAttemptsException":
				case "TooManyRequestsException":
					this.setState({
						messagens : [...oldMessages, {
							id: new Date(),
							type: "error",
							message: "Total de tentativas excedido."
						}]
					})					
					break;
				case "NotAuthorizedException":
					this.setState({
						messagens : [...oldMessages, {
							id: new Date(),
							type: "error",
							message: "Usuario ou senha inválido."
						}]
					})
					break;
				default:
					this.setState({
						messagens : [...oldMessages, {
							id: new Date(),
							type: "error",
							message: e.message
						}]
					})
					
					break;
			}
			
			console.log(e.message);
			
		} 
		this.setState({ isLoading: false });
	};

	render() {

		let inputs;
		if(this.state.updatePW) {

			inputs = (
					<>
					<div className="new-pass-rules">
						<h4>Troque sua senha.</h4>
						<p>No seu primeiro acesso, voce precisa trocar a senha.</p>
						<p>A nova senha deve obedecer a seguinte regra: <br/> 
						</p>
						<ul>
						<li>Letras maiúsculas e minúsculas;</li>
						<li>Números</li>
						<li>Caracteres especiais.</li>
						</ul>
					</div>
					
					
					<div className="field">
						<label htmlFor="oldPassword">Senha atual</label>
						<input type="password" autoFocus name="oldPassword" id="oldPassword" value={this.state.oldPassword} onChange={this.handleChange}/>
					</div>
					<div className="field">
						<label htmlFor="newPassword">Nova Senha</label>
						<input type="password" name="newPassword" id="newPassword" value={this.state.newPassword} onChange={this.handleChange}/>
					</div>
					<div className="field">
						<label htmlFor="newPasswordCheck">Repita nova Senha</label>
						<input type="password" name="newPasswordCheck" id="newPasswordCheck" value={this.state.newPasswordCheck} onChange={this.handleChange}/>
					</div>
					</>
				);				
			

		} else {			
			inputs = (
					<>
					<div className="field">
						<label htmlFor="email">Email</label>
						<input type="text" autoFocus name="email" id="email" value={this.state.email} onChange={this.handleChange}/>
					</div>
					<div className="field">
						<label htmlFor="password">Senha</label>
						<input type="password" name="password" id="password" value={this.state.password} onChange={this.handleChange}/>
					</div>
					</>
				);				
			
		}
        
		return (      
			<>
			<Spinner isLoading={this.state.isLoading} />
			<div className="login">
				<Logo />
				
				
				<form onSubmit={this.handleSubmit}>
					
					{inputs}

					<div className="messages">
						{this.state.messagens.map(message=>{					
							return <Message key={message.id} message={message.message} type={message.type}></Message> ;
						})}

					</div>
					

					<div className="field button">
					<button onClick={this.handleSubmit}>{this.state.updatePW ? "Atualizar" : "Login"}</button>
					</div>
                    
				
				</form>
			</div>
			</>
		);
	}
}

export default Login;