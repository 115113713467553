import Auth from '@aws-amplify/auth';

let config = {};
console.log("Env: " +process.env.REACT_APP_ENV)
switch (process.env.REACT_APP_ENV) {
//switch ("PROD") {
	case "DEV":		
	config = {
		API: {
			endpoints: [
				{
					name: 'aquisitor-api',
					endpoint: 'https://6s8aubtchb.execute-api.us-east-1.amazonaws.com/hml',
					region: 'us-east-1',
					custom_header: async () => { 						
						return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
					  }
				},
				{
					name: 'result-api',
					endpoint: 'https://3cmfk69vnh.execute-api.us-east-1.amazonaws.com/hml',
					region: 'us-east-1',
					custom_header: async () => { 							
						return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
					  }
				}
			]
		},
		
	
		Auth: {
			mandatorySignIn: true,
			region: 'us-east-1',
			userPoolId: 'us-east-1_HnKkoWbMY',
			identityPoolId: 'us-east-1:2495d2ac-21e2-4897-8b41-829cd353b72e',
			userPoolWebClientId: '5qc8qn0qepde9dp5jbcc8dg10q'
		},
		solve: {
			endpoint: 'https://painel-api-hml.progenos.com.br'

		}
	
	};
		
		break;
	case "HML":
		console.log("homologação")
		config = {
			API: {
				endpoints: [
					{
						name: 'aquisitor-api',
						endpoint: 'https://6s8aubtchb.execute-api.us-east-1.amazonaws.com/hml',
						region: 'us-east-1',
						custom_header: async () => { 						
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
						  }
					},
					{
						name: 'result-api',
						endpoint: 'https://3cmfk69vnh.execute-api.us-east-1.amazonaws.com/hml',
						region: 'us-east-1',
						custom_header: async () => { 							
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
						  }
					}
				]
			},
			
		
			Auth: {
				mandatorySignIn: true,
				region: 'us-east-1',
				userPoolId: 'us-east-1_HnKkoWbMY',
				identityPoolId: 'us-east-1:2495d2ac-21e2-4897-8b41-829cd353b72e',
				userPoolWebClientId: '5qc8qn0qepde9dp5jbcc8dg10q'
			},
			solve: {
				endpoint: 'https://painel-api-hml.progenos.com.br'

			}
		
		};
		
		break;

	case "PROD":
		console.log("prod")
		config = {
			API: {
				endpoints: [
					{
						name: 'aquisitor-api',
						endpoint: 'https://2smmlcafok.execute-api.us-east-1.amazonaws.com/prod',
						region: 'us-east-1',
						custom_header: async () => { 							
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
						  }
					}
				]
			},
			
		
			Auth: {
				mandatorySignIn: true,
				region: 'us-east-1',
				userPoolId: 'us-east-1_LVk0pZIsG',
				identityPoolId: 'us-east-1:81a3bc83-e9db-41bd-a3f6-fa7cca943947',
				userPoolWebClientId: 'uk4d3l05fn2m7ndtkh2bj1lgo'
			},
			solve: {
				endpoint: 'https://api-solve.progenos.com.br'

			}
		
		};
		
		break;
	case "NUTRYPROD":
		console.log("nutry-prod")
		config = {
			API: {
				endpoints: [
					{
						name: 'aquisitor-api',
						endpoint: 'https://pciqa875l0.execute-api.us-east-1.amazonaws.com/nutry-prod',
						region: 'us-east-1',
						custom_header: async () => { 							
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
							}
					},
					{
						name: 'result-api',
						endpoint: 'https://fgy4solmc7.execute-api.us-east-1.amazonaws.com/prd',
						region: 'us-east-1',
						custom_header: async () => { 							
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
							}
					}
				]
			},
			
		
			Auth: {
				mandatorySignIn: true,
				region: 'us-east-1',
				userPoolId: 'us-east-1_g2hzoIYlq',
				identityPoolId: 'us-east-1:e18defc3-6a56-4c8b-bd89-20238ed56306',
				userPoolWebClientId: '4duefkqthhb7sdcn43moahbohu'
			},
			
			solve: {
				endpoint: 'https://api-solve.progenos.com.br'

			}
		
		};
		
		break;
	default:
		config = {
			API: {
				endpoints: [
					{
						name: 'aquisitor-api',
						endpoint: 'https://6s8aubtchb.execute-api.us-east-1.amazonaws.com/hml',
						region: 'us-east-1',
						custom_header: async () => { 						
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
						  }
					},
					{
						name: 'result-api',
						endpoint: 'https://3cmfk69vnh.execute-api.us-east-1.amazonaws.com/hml',
						region: 'us-east-1',
						custom_header: async () => { 							
							return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
						  }
					}
				]
			},
			
		
			Auth: {
				mandatorySignIn: true,
				region: 'us-east-1',
				userPoolId: 'us-east-1_HnKkoWbMY',
				identityPoolId: 'us-east-1:2495d2ac-21e2-4897-8b41-829cd353b72e',
				userPoolWebClientId: '5qc8qn0qepde9dp5jbcc8dg10q'
			},
			solve: {
				endpoint: 'https://painel-api-hml.progenos.com.br'

			}
		
		};
		
		
		break;
}

 

export default config;