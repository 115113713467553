import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ( props ) => {

    switch (props.type) {
        case "icon":
            return (                
                <button title={props.title} className="button-icon" onClick={props.clickHandler} ><FontAwesomeIcon icon={props.iconName} /></button>
            );
        case "success":
            return (
                <button
                title={props.title}
                disabled={props.disabled}
                className={ "success " + (props.className ? props.className : "")}
                onClick={props.clicked}>{props.children}</button>
            )
        default:
            return (
                <button
                title={props.title}
                disabled={props.disabled}
                className={ "classic " + (props.className ? props.className : "")}
                onClick={props.clicked}>{props.children}</button>
            )            
    }

}
export default Button;
