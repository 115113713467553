import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Spinner = props => {
    if(props.isLoading) {
        return(
            <div className="loading">
            <div className="spinner" >
                <FontAwesomeIcon icon="spinner" spin />
                {props.children && <div className="text">{props.children}</div>}                
            </div>
            </div>   
        );
    } else {
        return(null)
    }

};

export default Spinner;
