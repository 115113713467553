import React, { useEffect, useState } from 'react';
import Home from './containers/Home/Home';
import Register from './containers/Register/Register';
import Exam from './containers/Exam/Exam';
import {Route, Switch} from 'react-router-dom';
import Login from './containers/Auth/Login';
import Report from './containers/Report/Report';
import Export from './containers/Export/Export';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import Spinner from './components/UI/Spinner/Spinner';
import { connect } from 'react-redux';
import * as actionTypes from './store/actions';
import comunicador from '@progenos/progenos-comunicador';
import * as version from './metadata.json';
import Reference from './containers/Reference/Reference';
import Logs from './containers/Logs/Logs';

function App(props)  {

  const [isLoading, setIsLoading] = useState(true);

  

  let [user, setUser] = useState(null)

  useEffect(() => {
    let updateUser = async authState => {
      try {              
        setUser(await Auth.currentAuthenticatedUser());        
        setIsLoading(false);
      } catch {
        setUser(null);
        setIsLoading(false);
      }
    }
    Hub.listen('auth', updateUser) // listen for login/signup events
    updateUser() // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser) // cleanup
  }, []);

  useEffect(() => {
    
    if(user) {          
      props.onUserLogged(user.attributes.email);
    }
    
  },[user, props]);

  const onLoadComunicador = props.onLoadComunicador;

  useEffect(() => {
    console.log("**** useEffect 2")    
    const com = new comunicador(10, 40, 1);
    onLoadComunicador(com);   
  },[onLoadComunicador]);

  
  function RenderApp(user) {
       
    if (user.user !== null) {       
      return (
      <Switch>
        <Route path='/register' component={Register} />   
        <Route path='/acquisition/:id' component={Exam} />     
        <Route path='/acquisition' component={Exam} />         
        <Route path='/report' component={Report} />                 
        <Route path='/export' component={Export} /> 
        <Route path='/referencia' component={Reference} />
        <Route path='/log' component={Logs} />
        <Route path='/' component={Home} />        
      </Switch> );
    }    
    
    return (<Login />);
  }

 

    return (    
        <div className="App">
          <div className="wrapper">
            {isLoading ? <Spinner isLoading={isLoading} /> : <RenderApp user={user} />  }                     
          </div>
          <div className="version">build: {version.build}</div>
        </div>   
    )
  
  
}


const mapDispatchToProps = dispatch => {
	return {
		onUserLogged: (user)=> dispatch({type: actionTypes.STORE_USER, user: user}),
    onUserLogOut: ()=> dispatch({type: actionTypes.REMOVE_USER}),
    onLoadComunicador: (_comunicador)=>dispatch({type: actionTypes.LOAD_COMUNICADOR, _comunicador:_comunicador})
	}
}

const mapStateToProps = state => {
	return {
		_user: state.user
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
