import * as actionsTypes from "./actions";

const initialState = {
    user: null
}

const rootReducer = (state = initialState, action) =>{

    switch (action.type) {

        case actionsTypes.STORE_USER:
            return {
                ...state,
                user: action.user
            };
        case actionsTypes.REMOVE_USER:
            return {
                ...state,
                user: null
            };
        case actionsTypes.LOAD_COMUNICADOR:
            return {
                ...state,
                _comunicador: action._comunicador
            };

        default:
            return state
    }
    
}

export default rootReducer