import React from 'react';

const Modal = props => {
    const conf = props.props;
    if(conf.show) {
        return (
            <div className="modal-background">
                <div className={"modal " + conf.modalType}>
                <h4>{conf.title}</h4>
                {conf.close && <button className="close">Fechar</button>}            
                {conf.message.map((line, index)=>(<p key={index}>{line}</p>))}
                <div className="actions">
                    {conf.actions && conf.actions.map(btn => (<button key={btn.label} onClick={btn.action} className={btn.className}>{btn.label}</button>))}
                </div>
                </div>
            </div>        
        )
    } else {
        return(null);
    }
    
}

export default Modal;