import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SpinnerLittle = props => {
   
    return(
       
        <div className="spinner-little" >
            <FontAwesomeIcon icon="spinner" spin />                            
        </div>
        
    );
    

};

export default SpinnerLittle;
