import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toolbar from "../../components/Navigarion/Toolbar/Toolbar";
import Spinner from "../../components/UI/Spinner/Spinner";
import Input from "../../components/UI/Imput/Imput";
import Button from "../../components/UI/Button/Button";
import exportToExcel from "./export";

import { Tooltip, notification } from "antd";
import dayjs from "dayjs";

import apiService from "../../services/api";
import Auth from "@aws-amplify/auth";
import ExamSelector from "../../components/UI/ExamSelector/ExamSelector";
import { formatCpf } from "../../helpers/helper";

function Logs() {
	const [user, setUser] = useState("");
	const [event, setEvent] = useState("");
	const [exams, setExams] = useState([]);
	const [date, setDate] = useState(getInitialDates());
	const [userName, setUserName] = useState("");
	const [company, setCompany] = useState("");

	const [totalizers, setTotalizers] = useState([]);
	const [eventsTypes, setEventsTypes] = useState([
		{ value: "", displayValue: "Selecione" },
	]);
	const [examTotalizers, setExamTotalizers] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [showFilters, setShowFilter] = useState(true);
	const [showExamsFilter, setShowExamsFilter] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [api, contextHolder] = notification.useNotification();
	const key = "updatable";

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		getTypes();
	}, [company]);

	async function getUser() {
		try {
			const user = await Auth.currentAuthenticatedUser({
				bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			});

			setUserName(user.username);
			setCompany(user.attributes["custom:company"]);
		} catch (err) {
			api.open({
				key,
				message: "Erro ao carregar usuário Logado",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		}
	}

	async function getTypes() {
		try {
			const { data } = await apiService.enums.find("EEventTypes");
			const events = [{ value: "", displayValue: "Selecione" }];
			Object.values(data).forEach((event) => {
				events.push({
					value: event.code,
					displayValue: event.type,
				});
			});
			setEventsTypes(events);
			getEvents(events);
		} catch (err) {
			api.open({
				key,
				message: "Erro ao buscar tipos de Eventos",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		}
	}

	async function getEvents(eventsTypes = null, cleaning = false) {
		setIsLoading(true);
		try {
			const body = {
				params: formatQuery(cleaning),
			};

			const { data } = await apiService.events.list(body);
			const { events, examCounters } = data
			setExamTotalizers(examCounters.groups)

			const totalizers = {};
			eventsTypes.forEach((event, i) => {
				if (i > 0) {
					totalizers[event.displayValue] = {
						label: event.displayValue,
						quantity: 0,
					};
				}
			});

			totalizers["Total"] = {
				label: "Total",
				quantity: events.length,
			};

			events.forEach((event) => {
				totalizers[event.type_description].quantity += 1;
			});

			setTotalizers(Object.values(totalizers));
			setSearchResults(events);
		} catch (err) {
			setSearchResults([]);
			api.open({
				key,
				message: "Erro ao buscar os Eventos",
				description: err.message,
				icon: (
					<FontAwesomeIcon icon="times-circle" style={{ color: "#ff0000" }} />
				),
			});
		} finally {
			setIsLoading(false);
		}
	}

	function getInitialDates() {
		const today = new Date();
		const firstDay = formatDate(
			new Date(today.getFullYear(), today.getMonth(), 1)
		);
		const lastDay = formatDate(
			new Date(today.getFullYear(), today.getMonth() + 1, 0)
		);

		return [dayjs(firstDay, "DD/MM/YYYY"), dayjs(lastDay, "DD/MM/YYYY")];
	}

	function cleanFilters(e) {
		e.preventDefault();
		setUser("");
		setEvent("");
		setDate(getInitialDates());
		setShowExamsFilter(false)

		getEvents(eventsTypes, true);
	}

	function formatNumberTwoCaracters(number) {
		return number < 10 ? "0" + number : number;
	}

	function formatDate(date = null, withHour = false) {
		date = date ? new Date(date) : new Date();

		const utcHours =  3
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const hours = date.getHours() + utcHours;
		const minutes = date.getMinutes();

		const formatedDate = `${formatNumberTwoCaracters(
			day
		)}/${formatNumberTwoCaracters(month)}/${date.getFullYear()}`;

		if (withHour)
			return `${formatedDate} ${formatNumberTwoCaracters(
				hours
			)}:${formatNumberTwoCaracters(minutes)}`;

		return formatedDate;
	}

	function formatQuery(justClient) {
		const params = {
			by_client: [company],
		};

		if (justClient) {
			let initialDate = getInitialDates()
			params.start_date = formatDate(initialDate[0].$d).replaceAll("/", "-");
			params.end_date = formatDate(initialDate[1].$d).replaceAll("/", "-");

			return params;
		}

		if (user !== "") params.by_person = user;
		if (event !== "") params.by_event_type = event;
		if (exams.length !== 0) params.exams_codes = exams

		if (date !== null) {
			params.start_date = formatDate(date[0].$d).replaceAll("/", "-");
			params.end_date = formatDate(date[1].$d).replaceAll("/", "-");
		}

		return params;
	}

	function formatExams(exams, all = false) {
		exams = exams.map(exam => exam.description)
		let examsToShow = "";
		const limitExams = all ? exams.length : exams.length > 5 ? 5 : exams.length;
		for (let i = 0; i < limitExams; i++) {
			if (!exams[i + 1]) {
				examsToShow += `${exams[i]}`;
			} else if (exams[i + 1] && i + 1 === limitExams) {
				examsToShow += `${exams[i]}... `;
			} else {
				examsToShow += `${exams[i]}, `;
			}
		}

		return examsToShow;
	}

	function formatExamsToExport(examTotalizers) {
		examTotalizers.forEach(group => {
			group.title = `Grupo: ${group?.description}`
			group.subgroups.forEach(subGroup => {
				subGroup.title = `Subgrupo: ${subGroup?.description}`
			})
		})

		return examTotalizers
	}

	const handleOnExportExcel = () => {
		const rows = searchResults.map((event) => {
			return {
				"CPF": formatCpf(event.personal_id),
				"ID": event.acquisition_id,
				Código: event.type,
				"Tipo do Evento": event.type_description,
				"Usuário Solicitado": event.Person.registry_sub,
				"Nome do Usuário": event.Person.name,
				Data: formatDate(event.event_date, true),
				"Quantidade de Exames Solicitados": event?.exams?.length || 'Sem exames solicitados',
				"Exames Solicitados": event?.exams?.length > 0 ? event.exams.reduce((accumulator, value, currentIndex) => `${currentIndex === 0 ? '' : accumulator + ' | '}${value?.description ?? ''}`, "") : ''
			};
		});

		const exams_formatter = formatExamsToExport(examTotalizers)

		exportToExcel(
			rows,
			totalizers,
			exams_formatter,
			`eventList_${formatDate()}.xlsx`
		);
	};

	return (
		<>
			{contextHolder}
			<Toolbar userName={userName} />
			<Spinner isLoading={isLoading} />
			<div className="container">
				<div className="header">
					<h2>Lista de Eventos</h2>
					<div className="icons">
						<Tooltip
							placement="top"
							title={showExamsFilter ? "Esconder filtros" : "Exibir filtros"}
						>
							<div
								className={searchResults.length > 0 ? "icon" : "disabled icon"}
								onClick={() => {
									if (searchResults.length > 0) {
										setShowFilter((current) => !current);
									}
								}}
							>
								{showFilters ? (
									<FontAwesomeIcon icon="times" />
								) : (
									<FontAwesomeIcon icon="filter" />
								)}
							</div>
						</Tooltip>

						<Tooltip
							placement="top"
							title={
								showExamsFilter
									? "Esconder filtro de exames"
									: "Exibir filtro de exames"
							}
						>
							<div
								className={searchResults.length > 0 ? "icon" : "icon"}
								onClick={() => {
									// if (searchResults.length > 0) {
									setShowExamsFilter((current) => !current);
									// }
								}}
							>
								{showExamsFilter ? (
									<FontAwesomeIcon icon="eye-slash" />
								) : (
									<FontAwesomeIcon icon="eye" />
								)}
							</div>
						</Tooltip>

						<Tooltip placement="top" title="Exportar">
							<div
								className={searchResults.length > 0 ? "icon" : "disabled icon"}
								onClick={() => {
									if (searchResults.length > 0) {
										handleOnExportExcel();
									}
								}}
							>
								<FontAwesomeIcon icon="file-excel" />
							</div>
						</Tooltip>
					</div>
				</div>
				{showFilters && (
					<form>
						<>
							<Input
								id="user"
								label={"Usuário"}
								elementType={"input"}
								value={user}
								elementConfig={{ placeholder: "Digite o nome de usuário" }}
								changed={(event) => {
									setUser(event.target.value);
								}}
							/>
							<Input
								label={"Tipo do Evento"}
								elementType={"select"}
								id="events"
								elementConfig={{
									options: eventsTypes,
								}}
								value={event}
								changed={(event) => {
									setEvent(event.target.value);
								}}
							/>

							<Input
								label={"Data"}
								elementType={"dateRange"}
								id="date"
								value={date}
								elementConfig={{
									defaultValue: getInitialDates(),
								}}
								classesToInput={"dateRange"}
								changed={(event) => setDate(event)}
							/>

							<Button
								className={"clean btn-height"}
								clicked={(e) => cleanFilters(e)}
								type="success"
								onClick
							>
								Limpar
							</Button>

							<Button
								className={"classic btn-height"}
								clicked={(e) => {
									e.preventDefault();
									getEvents(eventsTypes);
								}}
								onClick
							>
								Procurar
							</Button>
						</>
						{showExamsFilter && (
							<ExamSelector onChangeSelectedExams={(e) => setExams(e)} />
						)}
					</form>
				)}
				<table>
					<thead>
						<tr>
							<th style={{ paddingLeft: "10px", paddingRight: "10px" }}>CPF</th>
							<th style={{ paddingLeft: "10px", paddingRight: "10px" }}>ID</th>
							<th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Código</th>
							<th>Tipo do Evento</th>
							<th>Nome do Usuário</th>
							<th>Data</th>
							<th>Quantidade de Exames Solicitados</th>
							<th>Exames Solicitados</th>
						</tr>
					</thead>

					<tbody>
						{searchResults &&
							searchResults.map((item) => {
								return (
									<tr key={item.id}>
										<td style={{ minWidth: "125px" }}>{item.personal_id && formatCpf(item.personal_id)}</td>
										<td style={{ minWidth: "75px" }}>{item.acquisition_id}</td>
										<td>{item.type}</td>
										<td style={{ minWidth: "150px" }}>{item.type_description}</td>
										<td style={{ minWidth: "150px" }}>{item.Person.name}</td>
										<td style={{ minWidth: "150px" }}>{formatDate(item.event_date, true)}</td>
										<td style={{ minWidth: "200px" }}>{item?.exams?.length || "Sem exames solicitados"}</td>
										{!item?.exams ? (
											["Cadastrar coleta", "Consultar resultados"].includes(item.type_description) ? <td>Sem Coletas Solicitadas</td> : <td> - </td>
										) : (
											<Tooltip
												placement="top"
												title={formatExams(
													item.exams,
													true
												)}
											>
												<td style={{ minWidth: "150px" }}>
													{formatExams(item.exams)}
												</td>
											</Tooltip>
										)}
									</tr>
								);
							})}
					</tbody>
				</table>

				{searchResults.length === 0 && (
					<div className="no-data">
						<h2>Sem eventos para exibir</h2>
						<p>Verifique se os filtros correspondem à busca realizada</p>
					</div>
				)}

				<div className="totalizers">
					<h2>Totalizadores</h2>
					<div className="totalizers-box">
						{totalizers &&
							totalizers.map((item, i) => {
								return (
									<div key={i}>
										<p>{item.label}</p>
										<span>{item.quantity} </span>
									</div>
								);
							})}
					</div>
				</div>
				<div className="exam-totalizers">
					<h2>Totalizadores dos exames</h2>
					<div className="info">
						<FontAwesomeIcon icon="info-circle" className="info-icone" />
						<span>
							Esse totalizador mostra a quantidade de cada exame solicitado aos
							eventos do tipo "Cadastrar Coleta" e "Consultar Resultado". Os valores são alterados com
							base nos filtros aplicados no início da tela.
						</span>
					</div>
					<div className="totalizers-box exams">
						{examTotalizers &&
							examTotalizers.map((group) => {
								return group.subgroups.map((subgroup) => {
									return (
										<div key={subgroup.description}>
											<p className="title">{group.description}</p>
											<p
												className={subgroup.description === "" ? "spacer" : ""}
											>
												{subgroup.description}
											</p>
											{subgroup.exams.map((exam) => {
												return (
													<section
														className="exam-totalizer"
														key={exam.description}
													>
														<span>{exam.description}</span>
														<span>{exam.count ?? 0} </span>
													</section>
												);
											})}
										</div>
									);
								});
							})}
					</div>
				</div>
			</div>
		</>
	);
}

export default Logs;
