export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}

export const maskInput = (value, mask) => {

    if (!mask) { return value };
    
    switch (mask) {
        case "cpf":
            return value.replace(/[^0-9]/g, '').substring(0, 11);                  
    
        default:
            return value;            
    }
}


export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const crcValidate = (byteArray) => {
    
    let check = false;
    let crc = 0;
    let checksum = getCRC(byteArray);

    crc = parseInt(byteArray[byteArray.length + -2]);
    crc <<= 8;
    crc |= parseInt(byteArray[byteArray.length + -1]);

    check = checksum === crc ? true : false;
    return check;

}

const getCRC = (byteArray) => {
    
    let checksum = 0xFFFF;
    for (let index = 0; index < (byteArray.length - 2); index++) {
        const myByte = byteArray[index];
        checksum ^= parseInt(myByte);
        
        for (let a = 0; a < 8; a++) {
            if ((checksum & 0x0001) === 0) {
                checksum >>= 1;
            }               
            else  {
                checksum = (checksum >> 1) ^ 0xC001;
            }            
        }
    }
    return checksum;

}