import React from 'react';
import Logo from '../Logo/Logo';
import {useHistory, useLocation} from 'react-router-dom';

import Button from '../../UI/Button/Button';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';

const Toolbar = (props) => {

    const history = useHistory();

    const logout = event =>{                
        Auth.signOut().then(data => console.log(data))
        .catch(err => console.log(err));
    }

    const goHome = ()=> {
        history.push("/")
    }

    let homeButton;

    if (useLocation().pathname !== "/") {
        homeButton =  <Button title={"Inicio"} type={"icon"} clickHandler={goHome} iconName={"home"} />
        // <Link className="home-button" to="/"><FontAwesomeIcon icon="home" /></Link>;
    } 
    
    return(
        <header>
       
            <Logo />            
            

        {/* <FontAwesomeIcon icon="sign-in-alt" />
            <FontAwesomeIcon icon="user-plus" />
            <FontAwesomeIcon icon="hand-paper" />
        */}

    
        <div className="side-actions">
        <div className="userName">Voce está logado como:<br/> <strong>{props._user}</strong></div>
            {homeButton}
            <Button title={"Sair"} type={"icon"} clickHandler={logout} iconName={"sign-in-alt"} />           
        </div>
            
          
        </header>
    )
} 


const mapStateToProps = state => {  
	return {
		_user: state.user
	}
}

export default connect(mapStateToProps)(Toolbar);