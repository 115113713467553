import React from 'react';
import progenosLogo from '../../../assets/images/logo-progenos.svg';

const Logo = (props) => (
    <div className='progenos-logo'>
        <img src={progenosLogo} alt="Progenos" />
    </div>
)


export default Logo;