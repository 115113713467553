import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import Toolbar from '../../components/Navigarion/Toolbar/Toolbar';


class Home extends Component { 
    
    render() {

        return (
            <>
            <Toolbar/>
            <div className='home'>      
                <Link to="/acquisition" className="big-button" > <FontAwesomeIcon icon="hand-paper" /> Realizar Análise</Link>
                <Link to="/register" className="big-button" > <FontAwesomeIcon icon="user-plus" />  Cadastrar Coleta</Link>   
                <Link to="/report" className="big-button" > <FontAwesomeIcon icon="poll-h" />  Consultar Resultados</Link> 
                <Link to="/referencia" className="big-button" > <FontAwesomeIcon icon="flask" />  Metodos de Referência</Link> 
                <Link to="/log" className="big-button" > <FontAwesomeIcon icon="list-alt" />  Consulta de Logs</Link> 
            </div>  
            </>
        )
    }

}

export default Home;